// 茶叶加工技艺监测数据
import SHZT from './sn-table-column-shzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'MC',
                    label: '名称',
                    minWidth: 200,
                    showoverflowtooltip: true
                }, {
                    prop: 'MS',
                    label: '描述',
                    minWidth: 100,
                    substr: true
                },
                SHZT,
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            },
        },
    },
    snButton: {
        buttonData: [
            // {
            //     isShow: false,
            //     btnType: 'button',
            //     operateType: 'buttonNew',
            //     showMethod: 'showNewBtn',
            //     name: '新建',
            //     round: true,
            //     backColor: '#28ccd9',
            // },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        selectData: [],
        searchBtn: {
            // buttonData: [{
            //     btnType: 'button',
            //     operateType: 'conditionSearchBtn',
            //     name: '高级查询',
            //     round: true,
            //     backColor: '#2a63d5',
            //     color: '#fff'
            // }]
        }
    }
};
export default selfData;